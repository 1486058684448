var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.copyAlert),expression:"copyAlert"}],attrs:{"type":"info","text":""}},[_vm._v(" "+_vm._s(_vm.msgtokencopiado)+" ")]),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.alertStatus),expression:"alertStatus"}],attrs:{"type":"success","text":""}},[_vm._v(" "+_vm._s(_vm.msgAlertStatus)+" ")]),_c('base-card',[_c('v-card-text',[_c('v-card-title',[_vm._v(" Listados Clientes FE "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.details,"search":_vm.search},scopedSlots:_vm._u([{key:"item.Estado",fn:function(ref){
var item = ref.item;
return [(item.Estado === 'T')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"yellow darken-2","right":""}},[_vm._v(" mdi-alpha-t-circle ")]):(item.Estado === 'P')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success darken-2","right":""}},[_vm._v(" mdi-alpha-p-circle ")]):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info darken-2","right":""}},[_vm._v(" mdi-alpha-d-circle ")])]}},{key:"item.Activo",fn:function(ref){
var item = ref.item;
return [(item.Activo === 1)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success darken-2","right":""}},[_vm._v(" mdi-account-check ")]):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning darken-2","right":""}},[_vm._v(" mdi-account-cancel ")])]}},{key:"item.TokenEnterprise",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.TokenEnterprise),expression:"item.TokenEnterprise",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},[_vm._v(" mdi-content-copy ")])]}},{key:"item.TokenPassword",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.TokenPassword),expression:"item.TokenPassword",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},[_vm._v(" mdi-content-copy ")])]}},{key:"item.ActivarInactivar",fn:function(ref){
var item = ref.item;
return [(item.Activo == 1)?_c('v-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer","text-align-last":"center"},attrs:{"color":"red darken-3"},on:{"click":function($event){return _vm.activeInactiveClients(item)}}},[_vm._v(" mdi-account-cancel ")]):_c('v-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"success darken-3"},on:{"click":function($event){return _vm.activeInactiveClients(item)}}},[_vm._v(" mdi-account-check ")])]}},{key:"item.URLImagen",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"id":"preview"}},[_c('img',{staticStyle:{"width":"60%"},attrs:{"src":item.URLImagen}})])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }